.delete-modal {

  & .ant-modal-body {
    padding: 35px;
  }

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    margin-bottom: 18px;
  }

  &__subtitle {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 30px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__btn {
    width: 160px;
  }
}
