.footer-component {
  font-family: $font-primary;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 0.8rem;
  padding: 0.625rem;

  @media screen and (min-width: 640px) {
    font-size: 1rem;
    padding: 0.625rem 0;
  }
}

.footer-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: $color-dark;
  a {
    color: $color-dark;
    text-decoration: none;
  }
}
