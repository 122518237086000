.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 22px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
  margin: 0 10px;

  &__label {
    cursor: pointer;
  }

  & .switch__checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1890FF;
    border-radius: 100px;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      bottom: 2px;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      transition: .5s;
    }
  }

  & input:checked + &__slider::before {
    transform: translateX(22px);
  }
}