.user-actions {
  display: flex;
  align-items: center;
  color: $color-dark;

  &__team-select {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: $color-dark;
    margin-right: 3rem;
    min-width: 120px;

    & .ant-select-arrow {
      color: $color-dark;
    }
    // .ant-select-selector {
    //   padding: 0 !important;
    // }
  }

  &__icon {
    cursor: pointer;

    &:not(:first-of-type) {
      margin-left: 32px;
    }
  }

  &__avatar {
    background-color: $color-primary;
    // margin-left: 33px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $color-off-white;
    cursor: pointer;
    position: relative;
  }

  &__dropdown {
    // min-width: 275px;
    padding: 1.25rem 1.5rem 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    position: relative;
    background-color: #fff;
    top: 100%;
    left: -46px;

    // &::after {
    //   content: '';
    //   height: 1px;
    //   width: 214px;
    //   position: absolute;
    //   left: 12px;
    //   right: 8px;
    //   top: 64px;
    //   background-color: rgba(0, 0, 0, 0.08);
    // }
  }
  &__dropdown-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid $color-grey;
    padding-bottom: 1.5rem;
    .ant-avatar {
      margin: 0;
    }
  }

  &__dropdown-user-info {
    display: flex;
    flex-direction: column;
  }

  &__dropdown-user-name {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    max-width: 140px;
    word-wrap: break-word;
  }
  &__dropdown-user-email {
    font-size: 0.75rem;
    color: $color-darker-grey;
    max-width: 140px;
    word-wrap: break-word;
  }
  &__dropdown-user-role {
    font-size: 1rem;
    text-transform: capitalize;
  }

  &__account-link,
  &__account-link:link,
  &__account-link:active,
  &__account-link:visited,
  &__account-link:hover {
    text-decoration: none;
  }

  &__account-link {
    display: block;
    padding: 27px 12px 18px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  &__account-bottom {
    padding-top: 1.5rem;
    // padding: 13px 20px 13px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__btn {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    padding: 0;

    svg {
      margin: 0;
      padding: 0;
    }
  }
  &__btn-label {
    margin-left: 1rem;
    font-weight: 400;
  }

  &__account-link {
    color: $color-primary;

    &:hover {
      color: $color-primary-dark;
    }
  }

  &__account-bottom {
    .ant-select-selection-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
