.ant-table {
  font-size: 1em;
}

.ant-table table {
  border: 0 !important;
  border-radius: 2px;

  tbody {
    > tr {
      background-color: #fff;

      &:nth-child(2n + 1) {
        background-color: #fff;
      }
    }
  }
}

// EVENT ERROR TABLE
.ant-table-wrapper.event-error-table table thead tr th {
  background-color: #f8f8f8;
  color: #aeaeae;
  font-size: 12px;
  font-family: 'Noto Sans';
  border: 0;
}

.ant-table-wrapper.event-error-table table tbody tr td {
  background-color: #ffffff;
  color: #333333;
  font-size: 14px;
  font-family: 'Noto Sans';
  border: 0;
  box-shadow: inset 0px -1px 0px rgba(226, 234, 241, 0.5);
  height: 80px;
}

// END EVENT ERROR TABLE

.ant-table-thead > tr > th {
  // background-color: $c-primary;
  background: #36a9e1;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;

  &.ant-table-column-has-actions {
    &.ant-table-column-has-sorters:hover {
      background-clip: padding-box;
      background-color: darken($c-primary, 3);
    }

    &.ant-table-column-has-filters {
      &:hover {
        .ant-table-filter-icon:hover,
        .anticon-filter:hover {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .anticon-filter {
        &.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .ant-table-filter-icon.ant-table-filter-open {
        background-color: darken($c-primary, 3);
        color: #fff;
      }
    }
  }

  &.ant-table-column-sort {
    background-color: darken($c-primary, 3);
  }

  .ant-table-column-sorter {
    color: #fff;

    &-down.on,
    &-up.on {
      color: yellow;
    }
  }

  .ant-table-filter-selected.anticon-filter {
    color: yellow;
  }
}

.ant-table-bordered {
  .ant-table-thead > tr > th {
    border-right-color: lighten($c-primary, 5);
  }

  .ant-table-tbody > tr > td {
    border-right: 0;
    border-right: 1px solid white;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 0;
  border-right: 1px solid white;
  padding: 12px;
  font-size: 13px;
}

.ant-table-thead > tr > th {
  border-right-color: lighten($c-primary, 5);
}

.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0 !important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):nth-child(2n) > td {
  background-color: #f0f1f4;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #fcfcfd;
}

.ant-table-thead > tr > th .ant-table-filter-icon,
.ant-table-thead > tr > th .anticon-filter {
  color: $color-white;
}

@media only screen and (max-width: 1500px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 12px 6px !important;
  }

  i.controls::before {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1250px) {
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 22px !important;
  }

  .ant-table-thead > tr > th {
    font-size: 10px !important;
  }
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}

.table-actions {
  a {
    &:hover {
      opacity: 0.5;
    }
  }
}

.ant-table-filter-dropdown-link {
  &:hover {
    cursor: pointer;
  }
}

/* MANAGE BLOCKS/QOOS/QOOLECTIONS TABLE */

.table-manage {
  height: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 50px;

  .ant-spin-nested-loading,
  .ant-spin-container {
    position: unset;
  }

  .ant-table-column-sorter {
    visibility: collapse;
  }

  .ant-table {
    // padding: 0 28px;
    font-family: 'Noto Sans', sans-serif !important;

    .ant-table-container {
      border-left: none;
    }

    .ant-table-thead > tr > th {
      font-family: 'Noto Sans', sans-serif;
    }

    th {
      background: $color-off-white;
      border-right: 0 !important;
      padding: 24px 14px !important;
      padding-left: 6px !important;
      -webkit-user-select: none;
      /* Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE10+/Edge */
      user-select: none;
      /* Standard */
    }

    th.ant-table-cell {
      box-shadow: none;
    }

    .ant-table-column-title,
    th.ant-table-cell {
      font-size: 12px;
      color: $color-darker-grey;
      letter-spacing: 0.1em;
      font-weight: 500;
    }

    .ant-table-row,
    tr:nth-child(2n + 1) {
      background: #fff;
    }

    .ant-table-cell {
      color: $color-dark;
      font-size: 14px;
      border-right: 0 !important;
      box-shadow: inset 0px -1px 0px rgba(226, 234, 241, 0.5);
    }

    .ant-tag {
      border: none;
      padding: 3px 8px;
      border-radius: 3px;
      font-size: 14px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-pagination {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 260px);
    background: rgba(255, 255, 255, 0.75);
    padding: 10px 0;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 32px;
    height: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-pagination-prev {
    margin-right: 33px !important;
  }

  .ant-pagination-next {
    margin-left: 33px !important;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-item:hover .ant-pagination-item-link,
  .ant-pagination-item:hover a {
    color: $color-primary;
  }

  .ant-pagination-item-active {
    background: $color-primary-3;
    color: $color-primary;
    border: none;
  }

  .anticon-filter {
    color: $color-dark-grey !important;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background: $color-off-white;
  }

  .ant-table-filter-column {
    justify-content: flex-start;

    .ant-table-column-title {
      flex: 0;
    }
  }
}

.table-header {
  background: #fff;
  padding: 24px 28px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;

  .switch-container {
    margin: 0;
    justify-content: flex-start;

    .switch__btn {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-darker-grey;

      &--active {
        background: $color-light-grey;
        border: 1px solid $color-grey;
        border-radius: 3px;
        color: $color-primary;
      }
    }
  }

  &__search,
  &__status-select {
    width: 214px;
    border-radius: 3px;
  }

  &__search {
    margin-right: 8px;
    position: relative;

    .ant-input-search-button {
      height: 32px;

      svg {
        margin-left: 0;
      }
    }

    .ant-input-prefix {
      margin-right: 12px;

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  &__status-select {
    text-align: left;
  }

  &__grid-filters {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    & > .dropdown-small {
      text-align: left;
      min-width: 175px;
    }
  }
}

/* MANAGE BLOCKS/QOOS/QOOLECTIONS GRID */
.grid-manage {
  background: #fff;
  padding: 24px 28px;
  padding-top: 0;
  font-family: 'Noto Sans', sans-serif;

  .ant-list-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 160px);
    column-gap: 44px;
    row-gap: 36px;
  }

  .ant-list-item {
    flex-direction: column;
    padding: 0;
    border-bottom: 0;
  }

  .ant-card {
    width: 100%;
    height: 160px;
    margin-bottom: 10px;
  }

  .ant-card-bordered {
    border: 1px solid $color-grey;
    border-radius: 3px;
  }

  .ant-card-body {
    width: 100%;
    height: 100%;
  }

  .more-icon-manage {
    position: absolute;
    top: 0;
    right: 6px;
    line-height: normal;
    color: $color-darker-grey;
  }

  .item-name-manage {
    color: $color-dark;
  }
}

.grid-narrow-manage {
  background: #fff;
  padding: 0 28px;
  padding-bottom: 65px;
  font-family: 'Noto Sans', sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fit, 140px);
  column-gap: 44px;
  row-gap: 36px;

  .ant-list-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    column-gap: 44px;
    row-gap: 36px;
  }

  .ant-list-item {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    border-bottom: 0;

    .text-content-container p {
      margin-bottom: 0;
    }
  }

  .ant-card {
    width: 100%;
    height: 257px;
    margin-bottom: 10px;
  }

  .ant-card-bordered {
    border: 1px solid $color-grey;
    border-radius: 3px;
  }

  .ant-card-body {
    width: 100%;
    height: 100%;
    padding: 24px 0 5px 0;
  }

  .more-icon-manage {
    position: absolute;
    top: 0;
    right: 6px;
    line-height: normal;
    color: $color-darker-grey;
  }

  .item-name-manage {
    color: $color-dark;
  }
}

.table-tag {
  &--category,
  &--audience {
    background: $color-primary-3;
    color: $color-primary;
  }

  &--status {
    &-draft {
      background: #ffc19780;
      color: #f1995d;
    }

    &-in-approval {
      background: #3c9bef4d;
      color: $color-blue;
    }

    &-approved {
      background: #5fd7634d;
      color: #5fd764;
    }

    &-change_required {
      background: #b93a3a8f;
      color: #e31d1d;
    }

    &-shared {
      background: $color-primary-3;
      color: $color-primary;
    }

    &-published {
      background: #ff7bab4d;
      color: $color-pink;
    }

    &-retired {
      background: #90989e4d;
      color: $color-darker-grey;
    }

    &-template {
      background: #f3e009;
      color: $color-dark;
    }
  }
}

.grid-view-manage,
.table-manage {
  .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    padding: 10px 0;
  }

  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 32px;
    height: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-pagination-prev {
    margin-right: 33px !important;
  }

  .ant-pagination-next {
    margin-left: 33px !important;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-item:hover .ant-pagination-item-link,
  .ant-pagination-item:hover a {
    color: $color-primary;
  }

  .ant-pagination-item-active {
    background: $color-primary-3;
    color: $color-primary;
    border: none;
  }

  .ant-table colgroup:first-of-type {
    min-width: 200px;
  }
}

.ant-dropdown-menu-title-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.ant-dropdown-menu-item-selected {
  color: #000;
  background: none;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  padding-left: 0;
}

.ant-table-filter-dropdown {
  min-width: 168px;

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-checkbox-inner {
    background-color: transparent;
  }

  .ant-dropdown-menu-item:hover {
    background-color: $color-light-grey !important;
  }

  .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
    border: none;
  }

  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border: none !important;
    background: url('../../../../src/icons/checkmark_blue.svg');
    background-size: 17px 12px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
    border: none;
  }

  .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
    box-shadow: none;
  }
}

.table-manage {
  .ant-pagination {
    margin-top: 24px;
  }
}

.grid-view-manage {
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  position: relative;

  .grid-narrow-manage {
    flex-grow: 1;
  }
}

.ant-table-measure-row td {
  padding: 0 !important;
}
@media only screen and (max-width: 1500px) {
  .ant-table-thead > .ant-table-measure-row > th,
  .ant-table-tbody > .ant-table-measure-row > td {
    padding: 0 !important;
  }
}
