.actions-block {
  padding: 10px 15px;
  // margin-bottom: 20px;
  margin-bottom: 8px;
  background-color: $c-secondary;
  // border-radius: 2px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: $color-dark-grey;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}
