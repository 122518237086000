.homepage {

  &__updates {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    background-color: $bg-grey;
    border-radius: 4px;
    padding: 22px 27px;
    margin-top: 38px;
  }
}

.one {
  display: flex;

  .today {
    background-color: #FFFFFF;
    display: block;
    width: 30%;
    height: 26vh;
    margin: 20px 0 0 20px;
    padding: 20px;
    justify-content: flex-start;

    &__header {
      font-family: 'Titillium Web';
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #2A3A49;
      // text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      // -webkit-text-stroke:1px #000000;
      margin-bottom: 25px;

      @media only screen and (max-width: 1455px) {
        font-size: 22px;
      }

      @media only screen and (max-width: 1366px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 1276px) {
        font-size: 18px;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &-field {
        width: 29%;
        padding-top: 29%;
        position: relative;
        background-color: #455FE9;
        border-radius: 10px;

        .flower {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 12%;
          color: white;
        }

        .user-list {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 12%;
          color: white;
        }

        .user-square {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 12%;
          color: white;
        }

        .text {
          position: absolute;
          left: 0;
          bottom: 20%;
          right: 0;
          text-align: center;
          color: white;
          font-family: 'Titillium Web';
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.02em;


        }
      }

    }

    @media only screen and (max-width: 1400px) {
      &__content {

        &-field {
          text-align: center;

          svg {
            width: 90%;
          }

          .text {
            position: absolute;
            left: 0;
            bottom: 20%;
            right: 0;
            text-align: center;
            color: white;
            font-family: 'Titillium Web';
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.02em;
          }
        }

      }
    }

    @media only screen and (max-width: 1277px) {
      &__content {

        &-field {
          text-align: center;

          svg {
            width: 90%;
            margin-top: -10%;
          }


        }

      }
    }

    @media only screen and (min-width: 1600px) {
      &__content {

        &-field {
          text-align: center;

          svg {

            margin-top: 10%;
          }

          .text {

            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.02em;
          }
        }

      }
    }

    @media only screen and (min-width: 1750px) {
      &__content {

        &-field {
          text-align: center;

          svg {

            margin-top: 10%;
          }

          .text {

            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.02em;
          }
        }

      }
    }

    @media only screen and (min-width: 1900px) {
      &__content {

        &-field {
          text-align: center;

          svg {

            margin-top: 15%;
          }

          .text {
            bottom: 30%;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.02em;
          }
        }

      }
    }
  }

  .month {
    background-color: #FFFFFF;
    display: block;
    width: calc(70% - 40px);
    height: 26vh;
    margin: 20px 0 0 20px;
    padding: 20px;
    justify-content: flex-end;

    &__header {
      font-family: 'Titillium Web';
      font-weight: 600;
      font-style: semi-bold;
      font-size: 24px;
      line-height: 20px;
      color: #2A3A49;
      margin-bottom: 25px;

      @media only screen and (max-width: 1455px) {
        font-size: 22px;
      }

      @media only screen and (max-width: 1366px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 1276px) {
        font-size: 18px;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &-field {
        width: 29%;
        padding-top: 12%;
        position: relative;
        border: 2px solid #455FE9;
        border-radius: 10px;

        .numberinside {
          position: absolute;
          left: 2%;
          top: 5%;
          color: #2A3A49;
          font-family: 'Work Sans';
          font-weight: 700;
          font-size: 34px;
          line-height: 40px;
        }

        .subheaderinside {
          position: absolute;
          left: 2%;
          top: 40%;
          font-family: 'Titillium Web';
          color: #2A3A49;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
        }

        .thirdlineinside {
          position: absolute;
          left: 2%;
          top: 65%;
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;

          .linkrestinside {
            color: #5D5FEF;
          }
        }

      }
    }

    @media only screen and (max-width: 1400px) {
      &__content {

        &-field {


          .numberinside {

            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
          }

          .subheaderinside {

            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
          }

          .thirdlineinside {

            font-weight: 300;
            font-size: 14px;
            line-height: 8px;

            .linkrestinside {
              color: #5D5FEF;
            }
          }

        }
      }
    }
  }


}

.red {
  color: #FB5757;
}

.green {
  color: #5FD764;
}

.two {
  display: flex;

  .today2 {
    width: fit-content;
    height: fit-content;
    background-color: #FFFFFF;
    margin: 20px;

    &__header {
      margin-left: 20px;
      margin-top: 20px;
      font-family: 'Titillium Web';
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #2A3A49;
    }

    &__content {

      display: flex;
      justify-content: space-between;
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-gap: 20px;
      padding: 5px 10px 20px 10px;

      &-field {
        background-color: #455FE9;
        border-radius: 10px;
        // color: #FFFFFF;
        text-align: center;
        padding: 13px 10px;
        margin: 10px;

        // .user-list2 .user-square2 .flower {
        //   text-align: center;
        // }

        .text2 {

          text-align: center;
          color: white;
          font-family: 'Titillium Web';
          font-size: 13px;
          font-weight: 400;
          // line-height: 16px;
          // letter-spacing: -0.02em;
        }
      }
    }

  }

  .todo {
    background-color: #FFFFFF;
    display: block;
    width: 30%;
    height: 45vh;
    margin: 20px 0 20vh 20px;
    padding: 20px;
    justify-content: flex-start;

    &__header {
      font-family: 'Titillium Web';
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;

      &-left {
        color: #2A3A49;
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
      }

      &-right {
        color: #455FE9;
        font-family: 'Titillium Web';
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        // text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &__content {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      font-family: 'Noto Sans';

      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .statistics {
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    height: 45vh;
    margin: 20px 0 20vh 20px;
    padding: 20px;
    justify-content: flex-start;

    &__header {
      font-family: 'Titillium Web';
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;

      &-left {
        color: #2A3A49;
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
      }

      &-right {
        color: #455FE9;
        font-family: 'Titillium Web';
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        // text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &__content {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      font-family: 'Noto Sans';

    }

    &__metrics {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      font-family: 'Noto Sans';
      display: flex;
      justify-content: space-between;

      &__title {
        font-weight: 800;
        margin-bottom: 20px;
      }
    }
  }
}