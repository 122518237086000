.qoo-types-content {
    background-color: white;
}

.qoo-types-container {
    margin: 20px;
    .butty {
        margin-left: 20px;
        width: 176px;
    }
    .inppy {
        width: 214px;
    }

    .ant-input:placeholder-shown {
        width: 214px;
    }

    .ant-btn {
        line-height: 16px;
    }
}

.qoo-types-upper-part {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: center; 
}