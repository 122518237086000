.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: $font-button-weight;
  font-size: $font-button-size;
  line-height: $font-button-line-height;
  border-radius: 3px;
  height: 36px;
  color: $color-primary;
  // background: $color-white;
  background: transparent; // proveriti da li treba da bude white ili transparent
  border-color: $color-primary;
  text-transform: capitalize;

  span:only-child {
    width: 100%;
  }

  &:hover,
  &:focus {
    // background: $color-primary-3;
    color: $color-primary-hover;
    border-color: $color-primary-hover;
  }

  &.ant-btn-sm {
    height: 32px;
    font-size: 14px;
    line-height: 18px;
  }

  &.ant-btn-lg {
    height: 48px;
  }

  &.ant-btn-primary {
    color: $color-white;
    background: $color-primary;
    border-color: $color-primary;

    &:hover,
    &:focus {
      background: $color-primary-hover;
    }

    &.ant-btn-dangerous {
      color: $color-white;
      background: $color-warning-red;
      border-color: $color-warning-red;

      &:hover,
      &:focus {
        background: $color-warning-red-hover;
        border-color: $color-warning-red-hover;
        color: $color-white;
      }
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
      background: $color-primary-inactive;
      border: 1px solid $color-primary-inactive;
      color: $color-white-inactive;
    }

    &.approve-manual-btn {
      background: $color-mint;
      border: none;

      &:hover,
      &:focus {
        background: lighten($color-mint, 10%);
      }
    }
  }

  &.ant-btn-dangerous {
    color: $color-warning-red;
    border-color: $color-warning-red;

    &:hover,
    &:focus {
      background: transparent;
      color: $color-warning-red-hover;
      border-color: $color-warning-red-hover;
    }
  }

  & svg {
    vertical-align: middle;
    margin-left: 12px;
  }
}

// .ant-btn {
//     text-transform: uppercase;
//     height: 38px;
//     letter-spacing: .05em;
//     font-size: 13px;
//     border-radius: 2px;

//     .anticon {
//         vertical-align: text-top;
//     }
// }

// .ant-btn-primary {
//     // background-color: $c-primary;
//     // border-color: $c-primary;
//     background-color: #36a9e1;
//     border-color: #36a9e1;

//     &:hover, &:focus {
//         background-color: lighten($c-primary,3);
//         border-color: lighten($c-primary,3);
//     }
// }

// .ant-btn-secondary:hover, .ant-btn-secondary:focus {
//     color: #4f66a0;
//     background: #fff;
//     border-color: #4f66a0;
// }

// .ant-btn-danger {
//     background-color: $danger;
//     color: #fff;
//     border-color: transparent;

//     &:focus {
//         color: #fff;
//         background-color: darken($danger,5);
//         border-color: transparent;
//     }
// }
