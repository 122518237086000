.manage-layout {
  height: 100%;
}
.inner-layout {
  height: 100%;
  background: $color-light-grey;
}

.sidebar-inner {
  background-color: $color-white;
  border-right: 1px solid #d5dfe8;
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  &__category-field {
    height: 56px;
    border-bottom: 1px solid #d5dfe8;
    padding: 0 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    transition: all 0.2s ease-in-out;

    &--active,
    &:hover {
      background-color: #f4f6f7;
    }
  }

  &__required-icon {
    color: $color-warning-red;
    background: #fff;
    border-radius: 50%;
  }

  &__container-justify-spbet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (max-width: 1600px) {
    flex: 0 0 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    width: 200px !important;
  }
}

.header-inner {
  padding: 0;
  background-color: $color-white;
  height: 56px;
  border-bottom: 1px solid #d5dfe8;
}
