.transparency-slider {
  display: flex;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $color-dark;
  border: 1px solid $color-grey;
  border-radius: 3px;

  &__label {
    width: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $color-grey;
  }

  &__slider-container {
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}