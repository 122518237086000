.log {
  .login-logo {
    margin-bottom: 40px;
    width: 50%;
    height: 66px;
    width: auto;
  }

  .ant-input {
    border-radius: 3px;
  }

  .ant-input-password {
    border-radius: 3px;
  }

  .ant-btn {
    //   font-family: 'Titillium Web', sans-serif;
    //   font-size: 16px;
    //   font-weight: 600;
  }

  .ant-input:placeholder-shown {
    font-family: 'Titillium Web', sans-serif;
  }

  .ant-form-item-has-error .ant-form-item-explain-error {
    font-family: 'Titillium Web', sans-serif;
  }
}

.login-header {
  width: 100%;

  .ant-col-18 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  // .ant-form-item {
  //     &:first-child {
  //         margin-bottom: 12px;
  //     }
  // }

  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-title {
    font-family: 'Titillium Web', sans-serif;
    font-size: 36px !important;
    font-weight: 600 !important;
    line-height: 55px !important;
    letter-spacing: 0em !important;
    text-align: center;
  }

  .ant-card-body {
    padding: 5px;
  }
}

.login-button {
  background-color: #36a9e1;
  border-radius: 3px;
  width: 100%;
  justify-content: center;
}

.forgot-pass {
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  a {
    color: darkgray;
  }
}

.back-to-login {
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  a {
    color: darkgray;
  }
}