.company_tree_title {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 23px;
}
.company_tree_container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.company_tree,
.company_tree ul,
.company_tree li {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.company_tree {
    margin: 0 0 1em;
    text-align: center;
}
.company_tree,
.company_tree ul {
    display: table;
}
.company_tree ul {
    width: 100%;
}
.company_tree li {
    display: table-cell;
    padding: .5em 0;
    vertical-align: top;
}
.company_tree li:before {
    outline: solid 1px #666;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.company_tree li:first-child:before {
    left: 50%;
}
.company_tree li:last-child:before {
    right: 50%;
}
.company_tree code,
.company_tree span {
    border: solid 0.2em skyblue;
    border-radius: .4em;
    display: inline-block;
    margin: 0 .2em .5em;
    padding: .2em .5em;
    position: relative;
    font-size: 19px;
    font-weight: 600;
}
.company_tree ul:before,
.company_tree code:before,
.company_tree span:before {
    outline: solid 1px #666;
    content: "";
    height: .5em;
    left: 50%;
    position: absolute;
}
.company_tree ul:before {
    top: -.5em;
}
.company_tree code:before,
.company_tree span:before {
    top: -.55em;
}
.company_tree>li {
    margin-top: 0;
}
.company_tree>li:before,
.company_tree>li:after,
.company_tree>li>code:before,
.company_tree>li>span:before {
    outline: none;
}


