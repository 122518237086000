.flex-row {
  display: flex;
  gap: 1.5rem;
}
.homebase {
  &__layout {
    height: 100vh;
    overflow: auto;
  }
  &__wrapper {
    padding: 1.5rem;
  }
  &__content {
    @media screen and (min-width: 1921px) {
      max-width: 1660px;
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
  }
  &__column {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > *:first-child {
      flex: none;
    }
    & > *:last-child {
      flex: auto;
    }
  }
  &__container {
    flex: auto;
    padding: 1.5rem;
    background: $color-white;
    border-radius: 0.625rem;
    position: relative;
  }
  &__container-title {
    color: $color-black;
    font-size: $font-heading-size;
    font-weight: 600;
    line-height: $font-heading-line-height;
    text-transform: capitalize;
    margin-bottom: 1.5rem;
  }
  &__table {
    // position: relative;
    background: #fff;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .ant-btn svg {
      margin-left: 0;
    }

    .ant-spin-nested-loading,
    .ant-spin-container {
      position: unset;
    }

    .ant-table {
      font-family: $font-secondary, sans-serif !important;

      &-wrapper {
        width: 100%;
      }

      .ant-table-container {
        border-left: none;
      }

      .ant-table-thead > tr > th {
        font-family: $font-secondary, sans-serif;
      }

      th {
        background: $color-off-white;
        border-right: 0 !important;
        padding: 24px 14px !important;
        padding-left: 6px !important;
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
      }

      th.ant-table-cell {
        box-shadow: none;
      }

      .ant-table-column-title,
      th.ant-table-cell {
        font-size: 12px;
        color: $color-darker-grey;
        letter-spacing: 0.1em;
        font-weight: 500;
      }

      .ant-table-row,
      tr:nth-child(2n + 1) {
        background: #fff;
      }

      .ant-table-cell {
        color: $color-dark;
        font-size: 14px;
        border-right: 0 !important;
        box-shadow: inset 0px -1px 0px rgba(226, 234, 241, 0.5);
      }

      .ant-tag {
        border: none;
        padding: 3px 8px;
        border-radius: 3px;
        font-size: 14px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ant-pagination-item-active {
      border-color: $color-primary-3;
      background: $color-primary-3;
      color: $color-primary;
    }
    .ant-pagination-item:hover a,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
      color: $color-primary;
    }

    .ant-table-body {
      overflow-y: auto !important;
    }
  }
  &__view-more-btn {
    margin-top: 1.25rem;
    display: inline-block;
    cursor: pointer;
    border: none;
    box-shadow: none;
    color: $color-primary;
    font-family: $font-secondary;
    font-weight: 500;
    line-height: 160%;
  }
  &__list-group {
    display: flex;
  }
  &__list {
    flex-grow: 1;
    &:nth-child(1) {
      border-right: 1px solid $color-grey;
      padding-right: 2rem;
    }
    &:nth-child(2) {
      padding-left: 2rem;
    }
    &--single {
      padding: 0;
      padding-left: 0;
      &:nth-child(1),
      &:nth-child(2) {
        border: none;
        border-right: none;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__list-entry {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $color-grey;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  &__list-entry-icon {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-dark-grey;
    border: 1px solid $color-grey;
    border-radius: 3px;
  }
  &__list-entry-value {
    margin-right: 0.75rem;
    font-family: $font-primary;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 150%;
    color: $color-dark;
  }
  &__list-entry-label {
    margin-right: 1rem;
    font-family: $font-secondary;
    font-size: 1rem;
    font-weight: 500;
    line-height: 160%;
    color: $color-dark;
  }
  &__list-entry-info {
    cursor: pointer;
    color: $color-dark-grey;
  }
  &__simple-list {
    flex-grow: 1;
  }
  &__simple-list-entry {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid $color-grey;

    &:last-child {
      border-bottom: none;
    }
  }
  &__simple-list-entry-wrapper {
    display: flex;
    align-items: center;
  }
  &__simple-list-entry-label {
    font-family: $font-secondary;
    font-size: 1rem;
    font-weight: 500;
    line-height: 160%;
    color: $color-dark;
  }
  &__simple-list-entry-value {
    font-family: $font-primary;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 150%;
    color: $color-dark;
  }
  &__widget-info {
    margin-left: 1rem;
  }
  &__qoolections {
    display: flex;
    gap: 1.375rem;
    padding-bottom: 1rem;
    overflow: auto;
  }
  &__tooltip {
    max-width: 30rem;
    * {
      margin: 0;
      padding: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__card {
    font-family: $font-secondary;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    & > p,
    & > .ant-btn {
      flex-grow: 1;
    }

    & .ant-btn {
      max-height: 2.25rem;
    }
  }
  &__card-image {
    width: 100%;
    height: 149px;
    background: $color-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;

    svg {
      max-height: 85px;
    }
  }
}
