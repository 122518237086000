.company-settings {
  &__content {
    background-color: white;
    display: flex;
  }

  &__left {
    padding: 20px;
    width: 400px;
    height: 100vh;
    border-right: 1px solid #d5dfe8;
    border-radius: 3px;
    position: relative;

    &-text1 {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 6px;
      letter-spacing: -0.02em;
      text-align: left;
      margin-bottom: 20px;
    }

    &-input {
      display: flex;
      justify-content: space-between;

      .company-input {
        width: 170px;
        height: 40px;
      }

      .company-button {
        width: 170px;
        height: 40px;
      }

      .ant-input {
        border-radius: 3px;
      }
    }

    &-text2 {
      font-family: Noto Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 6px;
      letter-spacing: -0.02em;
      text-align: left;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  &__level-fields {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .ant-input {
      max-width: 150px;
      border-radius: 3px;
    }
    .level2-input {
      margin-left: 2rem;
    }
    .level3-input {
      margin-left: 4rem;
    }

    .level1-input,
    .level2-input {
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 2px;
        content: 'a';
        width: 15px;
        height: 30px;
        border-left: 1px solid red;
      }
    }
  }

  &__create-tree {
    position: relative;
    float: right;

    .create-tree-button {
      width: 170px;
      height: 40px;
    }
  }

  &__right {
    flex: 1;

    @media (min-width: 768px) {
      .ant-col-md-12 {
        display: block;
        flex: 1;
        max-width: 100%;
      }

      .ant-col-md-16 {
        display: block;
        flex: 1;
        max-width: 100%;
      }

      .ant-col-md-10 {
        display: block;
        flex: 1;
        max-width: 66.5%;
      }
    }

    .ant-btn {
      line-height: 19px;
      margin-top: 20px;
    }

    .panel-primary .panel-body .panel-heading {
      background-color: none;
      border-color: none;
    }

    .panel-heading .panel-title span {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }

    .panel-title {
      justify-content: center !important;
    }

    .panel-heading {
      padding: 0px !important;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  &__tabs {
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      flex: auto;
      justify-content: center;
    }
    .ant-tabs-tab-btn {
      color: $color-dark;
      font-family: $font-secondary;
      font-size: 0.88rem;
      line-height: 160%;
    }
  }
}
.ant-tabs-ink-bar {
  background: $color-primary !important;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 5px;
}

.branch-form-button {
  width: 100%;
}

.custom-button {
  border: none;
  color: $color-dark-grey;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
}
.ant-input-prefix {
  margin-right: 0.5rem;
}

.company-settings-builder {
  .table-manage {
    width: 100%;
    overflow: auto;
  }
  .table-manage .ant-table {
    padding: 0;
  }
  .ant-table-content {
    max-height: 80vh;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.ant-select-selector {
  overflow: auto;
}
