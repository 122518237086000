@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

// FONTS
$font-primary: 'Titillium Web', sans-serif;
$font-secondary: 'Noto Sans', sans-serif;

// COLORS
// Color PRIMARY
$color-primary: #4962e2;
$color-primary-hover: #6177ec;
$color-primary-light: #dbe0f8;
$color-primary-inactive: #b6c0f3;
$color-primary-dark: #2c40a7;
$color-primary-1: #6d81e8; //not used
$color-primary-2: #92a1ee; //not used
$color-primary-3: #dce2ff;
$color-blue-sky: #82deff; //not used

// Color WHITE
$color-white: #ffffff;
$color-off-white: #f8f8f8;
$color-white-inactive: rgba(255, 255, 255, 0.4);

// Color GREY
$color-darker-grey: #90989e;
// $color-dark-grey: #AEB8C0;
$color-dark-grey: #aeaeae;
$color-dark-grey-hover: #bababa;
$color-dark-grey-inactive: #dfdfdf;
$color-medium-grey: #bdc7cf;
// $color-grey: #D5DFE8; //not used
$color-grey: #e4e4e4;
$color-grey-hover: #e8e8e8;
$color-grey-inactive: #f4f4f4;
$color-light-grey: #f4f6f7;

// Color DARK
// $color-dark: #2A3A49;
$color-dark: #333333;
$color-dark-hover: #515151;
$color-dark-inactive: #adadad;
$color-dark-1: #41576b; //not used
$color-dark-2: #728291; //not used
$color-dark-3: #e5edf4; //not used

// Color WARNING RED
$color-warning-red: #fb5757;
$color-warning-red-light: #fedfdf;
$color-warning-red-hover: #fb5e5e;
$color-warning-red-inactive: #fdbfbf;

$color-success-green: #39bb82;
$color-attention-yellow: #fbd850;

$color-black: #333333; //not used (replaced)
$color-black-1: #4d4d4d; //not used
$color-black-2: #666666; //not used
$color-black-3: #dedede; //not used

$color-blue: #3c9aef;
$color-blue-1: #63aef2; //not used
$color-blue-2: #8ac2f5; //not used
$color-blue-3: #d9edff; //not used

$color-peach: #ffc197;
$color-peach-1: #ffc9a4; //not used
$color-peach-2: #ffd8be; //not used
$color-peach-3: #fff3eb; //not used

$color-mint: #31ddd4;
$color-mint-1: #65e6df; //not used
$color-mint-2: #83ebe5; //not used
$color-mint-3: #d3fffd; //not used

$color-pink: #ff7bac;
$color-pink-1: #ff95bd;
$color-pink-2: #ffb0cd;
$color-pink-3: #ffedf4;

//stare boje, obrisati kasnije
$bgColor: #fff;
$bodyColor: #f0f2f5;
$wht: #fff;

$k2: #fafafa;
$k3: #f7f7f7;
$k5: #f2f2f2;
$k10: #e6e6e6;
$k15: #d9d9d9;
$k20: #ccc;
$k25: #bfbfbf;
$k30: #b3b3b3;
$k40: #999;
$k50: #808080;
$k60: #666;
$k70: #4d4d4d;
$k80: #333;
$k90: #191919;
$k100: #000;

$base: #0071bc;
$txtColor: #696c74;

// $c-primary:#259dab;
//$c-primary:#4F66A0;
$c-primary: #216c97;
// $c-secondary:#464f63;
//$c-secondary:#DBE2EA;
$c-secondary: #dbe2ea;
$c-tertiary: #0092dd;

// $light:#f5f5f5;
$line: #ddd;
// $line2: #bdbdbd;
$darkBlue: #2e3192;
$hl: #ffcc00;
// $hl2: #e6e7ee;
// $blue: #0600e0;

$highlight: #5bbfde;
$danger: #d9534f;

$success: #a1ca51;
$error: #ce0000;

$purple: #444573;
$red: $error;
$yellow: #d5c93f;
$orange: orange;
$green: $success;
$gray: #5d737e;

//$bg-grey: rgba(228, 236, 244, 0.6);
$bg-grey: #eff4f8;

$c-blue: #3b8de0;

// $font-stack: "Lato", sans-serif;
$font-stack: 'Titillium Web', sans-serif;

// FONTS

// Font BODY
$font-body-weight: 400;
$font-body-size: 14px;
$font-body-line-height: 19px;

// Font BUTTON LABEL
$font-button-weight: 600;
$font-button-size: 14px;
$font-button-line-height: 22px;

// Font INPUT LABEL
$font-label-weight: 400;
$font-label-size: 14px;
$font-label-line-height: 19px;

// Font HEADING
$font-heading-size: 24px;
$font-heading-line-height: 37px;

// Font TOOLBAR CAPTION
$font-toolbar-weight: 400;
$font-toolbar-weight-selected: 500;
$font-toolbar-size: 11px;
$font-toolbar-line-height: 15px;

// Font LEFT MENU ITEM
$font-left-menu-item-weight: 500;
$font-left-menu-item-size: 12px;
$font-left-menu-item-line-height: 16px;
$font-left-menu-item-letter-spacing: 0.1em;
$font-left-menu-item-text-transform: uppercase;

// Font SIDEBAR
$font-sidebar-weight: 400;
$font-sidebar-weight-selected: 500;
$font-sidebar-size: 16px;
$font-sidebar-line-height: 160%;

// Font TOPBAR
$font-topbar-weight: 400;
$font-topbar-weight-selected: 500;
$font-topbar-size: 16px;
$font-topbar-line-height: 160%;
