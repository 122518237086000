.edit-footer {

  width: 100%;
  background-color: $bg-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;

  &__loading {
    color: #3B8DE0;
  }

  &__spinner {
    margin-right: 10px;
    font-size: 16px;
  }

  &__saving-text {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__btn {
    background-color: #3B8DE0;
    border-radius: 4px;
    color: $wht;
    text-transform: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-left: 11px;
  }

  &__undo,
  &__redo {
    font-size: 32px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      color: #e0d6d6;
    }
  }

  &__redo {
    margin-left: 20px;
    margin-right: 20px;
  }

}