.phone-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  & .marvel-device {
    transform-origin: center;

  }

  &__select-device {
    position: absolute;
    left: 50%;
    bottom: 70px;
    transform: translateX(-50%);
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-light-grey;
  }

  &__grid {
    width: 100%;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: -25px;
    font-size: 36px;
    cursor: pointer;
  }
}