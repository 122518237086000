.ant-modal {
  top: 150px;

  .anticon {
    vertical-align: 0 !important;
  }

  .ant-modal-body {
    font-family: 'Noto Sans', sans-serif;
  }

  &-footer {
    display: flex;
    justify-content: center;
  }
  &-wrap {
    z-index: 1002;
  }
}

.phone-preview-modal {
  top: 20px;
}

.save-qoolection-modal {
  top: 40px;
}

// ant popover max-width
.ant-popconfirm {
  max-width: 400px;
}
