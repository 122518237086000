.admins-content {
  background-color: white;
}

.admins-container {
  margin: 20px;

  .butty {
    margin-left: 20px;
    width: 160px;
  }

  .inppy {
    width: 214px;
  }

  .ant-input:placeholder-shown {
    width: 214px;
  }

  .ant-btn {
    line-height: 16px;
  }
}

.admins-upper-part {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  align-items: center;

}

.margin-top-20 {
  margin-top: 20px;
}

.email-exists-warning {
  color: #781419;
  font-weight: 600;
}