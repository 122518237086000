.main-sidebar {
  border-right: 1px solid $color-grey;

  &__logo {
    padding: 24px 16px;
    padding-bottom: 23px;
    border-bottom: 1px solid #d5dfe8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 32px;
      min-height: 32px;
    }
  }

  &__logo-icon-small {
    cursor: pointer;
  }

  &__collapse-icon svg {
    width: 12px;
    path {
      color: #2a3a49;
    }
  }

  & .ant-menu-vertical > .ant-menu-item,
  & .ant-menu-vertical-left > .ant-menu-item,
  & .ant-menu-vertical-right > .ant-menu-item,
  & .ant-menu-inline > .ant-menu-item,
  & .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  & .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  & .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  & .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 56px;

    & svg {
      height: 24px;
      width: 24px;
      color: $color-dark-grey;
    }
  }

  & .ant-menu-inline .ant-menu-item {
    margin-top: 0;
  }

  & .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }

  & .ant-menu-item-active,
  & .ant-menu-item-selected,
  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  & .ant-menu-submenu-active {
    // background-color: $color-light-grey;

    & svg {
      color: $color-primary;
    }
  }

  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  & .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: transparent;
  }

  & .ant-menu-item,
  & .ant-menu-item a,
  & .ant-menu-title-content {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $color-dark;
  }

  & .ant-menu-submenu-title {
    margin: 0;
    .ant-menu-submenu-expand-icon svg {
      width: 12px !important;
      path {
        color: #2a3a49;
      }
    }
    &[aria-expanded='false'] {
      .ant-menu-submenu-expand-icon svg {
        transform: rotate(0deg);
        transition: 0.2s ease-in-out transform;
      }
    }
    &[aria-expanded='true'] {
      .ant-menu-submenu-expand-icon svg {
        transform: rotate(90deg);
        transition: 0.2s ease-in-out transform;
      }
    }
  }

  & .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  & .ant-menu-inline .ant-menu-item::after {
    // right: auto;
    // left: 0;
    // border-right: 4px solid $color-primary;
    display: none;
  }

  & .ant-menu-title-content span,
  & .ant-menu-title-content a {
    display: flex;
    align-items: center;

    & svg {
      margin-right: 16px;
    }
  }

  & .ant-menu-inline-collapsed {
    & span.ant-menu-title-content,
    & i.ant-menu-submenu-arrow {
      display: none !important;
    }

    & .ant-menu-item,
    & .ant-menu-submenu {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      & .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        .ant-menu-submenu-expand-icon {
          display: none;
        }
      }
    }
  }

  & .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  & .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $color-dark;
  }
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
  & .ant-menu-item {
    display: flex;
    align-items: center;

    &:hover {
      // background: $color-light-grey;
      color: $color-dark;
    }

    & a:hover {
      color: $color-dark;
    }
  }
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span {
  margin-left: 16px;
}

//Custom icon size for Themes menu item
.themes-menu-item svg {
  width: 25px !important;
  height: 25px !important;
}

// .ant-menu-item:hover,
// .ant-menu-item-active,
// .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
// .ant-menu-submenu-active,
// .ant-menu-submenu-title:hover {
//   color: red;
// }

// main.ant-layout-content {

//   section.ant-layout.ant-layout-has-sider.site-layout-background {
//     height: 100%;
//   }
// }

// .ant-layout-sider {
//   .logo {
//     //border-bottom: 1px solid #dbe2ea;
//     text-align: center;
//     padding: 15px;
//     padding-top: 6px;
//     padding-bottom: 6px;
//     background-color: #1e8fc6;

//     img {
//       max-height: 100%;
//       max-width: 100%;
//     }

//     Button {
//       margin-top: 15px;
//     }
//   }

//   .ant-menu-submenu-title {
//     span {
//       i {
//         vertical-align: inherit;
//         position: relative;
//         top: -1px;
//       }
//     }

//     .ant-menu-submenu-arrow {
//       transform: translateY(2px);
//     }
//   }

//   .ant-menu-submenu-open {
//     .ant-menu-submenu-title {
//       .ant-menu-submenu-arrow {
//         transform: translateY(0);
//       }
//     }
//   }

//   .ant-menu-item {
//     margin-top: 0;
//     margin-bottom: 0;

//     &:first-child {
//       margin-top: 8px;
//     }

//     &:last-child {
//       margin-bottom: 8px;
//     }

//     a,
//     a:visited {
//       &:hover {
//         text-decoration: none;
//       }
//     }
//   }
// }

// .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
//   margin-bottom: 0;
//   margin-top: 0;
// }
// .ant-layout-sider .ant-menu-item:last-child {
//   margin-bottom: 0;
// }

// span.anticon {
//   vertical-align: 0.15em;
// }

// // main.ant-layout-content {
// //   padding: 0 0 0 15px !important;
// // }

// @media only screen and (max-width: 992px) {
//   .ant-layout-sider {
//     .logo {
//       border: none;
//       padding: 20px 0;
//       padding-left: 3px;
//       padding-right: 3px;
//     }
//   }

//   main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
//     display: flex;
//     flex-direction: column;
//   }

//   .ant-layout.ant-layout-has-sider > .ant-layout-content {
//     width: 100%;
//     padding: 0px !important;
//   }
// }

// .side-menu {
//   display:block;
//   background-color: $bg-grey;
//   z-index: 6;

//   @include media("<=lg"){
//     display: none;
//   }

//   &--collapsed {

//     @include media("<=lg"){
//       display: block;
//       flex: 0 0 66% !important;
//       max-width: 66% !important;
//       min-width: 66% !important;
//       width: 66% !important;
//       z-index: 1000;
//       margin-top: 61px;
//     }
//   }

//   &__logo-container {
//     position: relative;
//     height: 84px;
//   }

//   &__logo-link,
//   &__logo-link:link,
//   &__logo-link:visited,
//   &__logo-link:active {
//     text-decoration: none;
//   }

//   &__logo {
//     color: #000;
//     font-style: normal;
//     font-size: 36px;
//     font-weight: bold;
//     line-height: 42px;
//     display: inline;
//     margin: 0;
//     position: absolute;
//     top: 15px;
//     left: 33px;

//     &--collapsed {
//       left: 27px;
//     }
//   }

//   &__collapse-toggle {
//     color: #000;
//     cursor: pointer;
//     position: absolute;
//     top: 25px;
//     right: 18px;

//     &--collapsed {
//       right: -30px;
//     }

//     @include media("<=lg"){
//       display: none;
//     }
//   }
// }
