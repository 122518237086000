.h-f-create-new,
.block-builder,
body {
  & .tox-tinymce {
    border: none;
    z-index: 1001;
  }

  & .tox .tox-edit-area {
    border: 1px solid $color-grey;
    border-radius: 3px;
    margin-top: 8px;
  }

  & .tox .tox-toolbar-overlord {
    // padding: 10px;
  }

  & .tox .tox-toolbar {
    background: unset;

    &:first-of-type {
      margin-bottom: 5px;
    }
  }

  & .tox .tox-tbtn[aria-label='Fonts'] {
    width: 200px;
    height: 32px;
    border: 1px solid $color-grey;
    border-radius: 3px 0 0 3px;

    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    margin: 0;

    &:hover {
      background: $color-light-grey;
    }

    & .tox-tbtn__select-chevron svg {
      display: none;
    }

    & .tox-tbtn__select-chevron {
      width: 15px;
      height: 20px;
      background-image: url('../../../../src/icons/down-caret.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }

    & .tox-tbtn__select-label {
      cursor: pointer;
      width: 160px;
      font-family: $font-secondary;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .tox .tox-tbtn[aria-label='Font sizes'] {
    width: 104px;
    height: 32px;
    border-top: 1px solid $color-grey;
    border-bottom: 1px solid $color-grey;
    border-radius: 0;
    cursor: pointer;
    margin: 0;

    &:hover {
      background: $color-light-grey;
    }

    & .tox-tbtn__select-chevron svg {
      display: none;
    }

    & .tox-tbtn__select-chevron {
      width: 15px;
      height: 20px;
      background-image: url('../../../../src/icons/down-caret.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }

    & .tox-tbtn__select-label {
      cursor: pointer;
    }
  }

  & .tox .tox-split-button[aria-label='Text color'] {
    width: 88px;
    height: 32px;
    border: 1px solid $color-grey;
    border-radius: 0 3px 3px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0 8px;

    &:hover {
      box-shadow: none;
    }

    & .tox-tbtn:first-of-type {
      width: auto;
      height: auto;
    }

    & .tox-icon.tox-tbtn__icon-wrap {
      width: 48px;
      height: 16px;
      border: 1px solid $color-grey;
      border-radius: 3px;
      background: $color-dark;

      & svg {
        display: none;
      }
    }

    & .tox-split-button__chevron {
      cursor: pointer;
      width: 20px;
      height: 100%;
      background-image: url('../../../../src/icons/down-caret.svg');
      background-repeat: no-repeat;
      background-position: center center;

      & svg {
        display: none;
      }
    }
  }

  & .tox .tox-tbtn[aria-label='Bold'],
  & .tox .tox-tbtn[aria-label='Italic'],
  & .tox .tox-tbtn[aria-label='Underline'],
  & .tox .tox-tbtn[aria-label='Align left'],
  & .tox .tox-tbtn[aria-label='Align center'],
  & .tox .tox-tbtn[aria-label='Align right'],
  & .tox .tox-tbtn[aria-label='Justify'],
  & .tox .tox-tbtn[aria-label='More...'],
  & .tox .tox-tbtn[aria-label='Insert/edit link'] {
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;

    & svg {
      display: none;
    }

    & .tox-icon.tox-tbtn__icon-wrap {
      background: $color-dark;
      width: 22px;
      height: 22px;
    }

    &:hover {
      background: $color-light-grey;
      border: 1px solid $color-grey;

      & .tox-icon.tox-tbtn__icon-wrap {
        background: $color-primary;
      }
    }
  }

  & .tox .tox-tbtn[aria-label='Clear formatting'],
  & .tox .tox-tbtn[aria-label='Special character'],
  & .tox .tox-tbtn[aria-label='Left to right'],
  & .tox .tox-tbtn[aria-label='Right to left'],
  & .tox .tox-tbtn[aria-label='Help'],
  & .tox .tox-tbtn[aria-label='Superscript'],
  & .tox .tox-tbtn[aria-label='Subscript'] {
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background: $color-light-grey;
      border: 1px solid $color-grey;

      & .tox-icon.tox-tbtn__icon-wrap svg {
        fill: $color-primary;
      }
    }
  }

  & .tox .tox-tbtn--enabled[aria-label='Bold'],
  & .tox .tox-tbtn--enabled[aria-label='Italic'],
  & .tox .tox-tbtn--enabled[aria-label='Underline'],
  & .tox .tox-tbtn--enabled[aria-label='Align left'],
  & .tox .tox-tbtn--enabled[aria-label='Align center'],
  & .tox .tox-tbtn--enabled[aria-label='Align right'],
  & .tox .tox-tbtn--enabled[aria-label='Justify'],
  & .tox .tox-tbtn--enabled[aria-label='More...'] {
    background: $color-light-grey;
    border: 1px solid $color-grey;

    & .tox-icon.tox-tbtn__icon-wrap {
      background: $color-primary;
    }
  }

  & .tox .tox-tbtn--enabled[aria-label='Clear formatting'],
  & .tox .tox-tbtn--enabled[aria-label='Special character'],
  & .tox .tox-tbtn--enabled[aria-label='Left to right'],
  & .tox .tox-tbtn--enabled[aria-label='Right to left'],
  & .tox .tox-tbtn--enabled[aria-label='Help'],
  & .tox .tox-tbtn--enabled[aria-label='Superscript'],
  & .tox .tox-tbtn--enabled[aria-label='Subscript'] {
    background: $color-light-grey;
    border: 1px solid $color-grey;

    & .tox-icon.tox-tbtn__icon-wrap svg {
      fill: $color-primary;
    }
  }

  & .tox .tox-tbtn[aria-label='Bold'],
  & .tox .tox-tbtn[aria-label='Align left'],
  & .tox .tox-tbtn[aria-label='Insert/edit link'] {
    margin-left: 8px;
  }

  & .tox .tox-tbtn[aria-label='Bold'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/B.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Italic'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/I.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Underline'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/U.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Align left'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/align-text-left.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Align center'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/align-text-center.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Align right'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/align-text-right.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Justify'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/justify-text.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='More...'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/dots.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-tbtn[aria-label='Insert/edit link'] {
    & .tox-icon.tox-tbtn__icon-wrap {
      mask: url('../../../../src/icons/link.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox .tox-toolbar__group {
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  & .tox-split-button[aria-label='Numbered list'],
  & .tox-split-button[aria-label='Bullet list'] {
    margin: 0;
    margin-left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;

    &:hover,
    &[aria-expanded='true'] {
      background: $color-light-grey;
      border: 1px solid $color-grey;
      box-shadow: none;

      & .tox-tbtn.tox-split-button__chevron {
        background: $color-primary;
      }
    }

    & span.tox-tbtn:first-of-type {
      display: none;
    }

    & .tox-tbtn.tox-split-button__chevron {
      width: 100%;
      height: 100%;
      background: $color-dark;

      & svg {
        display: none;
      }
    }
  }

  & .tox-tbtn.tox-split-button__chevron {
    mask: url('../../../../src/icons/ph_list-bullets.svg');
    mask-repeat: no-repeat;
    mask-position: center center;
  }

  & .tox-split-button[aria-label='Bullet list'] {
    margin-left: 0;
  }

  & .tox-split-button[aria-label='Background color'] {
    margin: 0;
    height: 32px;
    border-radius: 3px;
    cursor: pointer;

    &:hover,
    &[aria-expanded='true'] {
      background: $color-light-grey;
      border: 1px solid $color-grey;
      box-shadow: none;

      & .tox-tbtn.tox-split-button__chevron svg,
      & .tox-tbtn .tox-icon.tox-tbtn__icon-wrap svg,
      & .tox-tbtn .tox-icon.tox-tbtn__icon-wrap svg g path {
        fill: $color-primary;
      }
    }

    & .tox-tbtn.tox-split-button__chevron.tox-tbtn--enabled {
      background: $color-light-grey;
    }
  }

  & .tox-split-button[aria-label='Numbered list'] {
    & .tox-tbtn.tox-split-button__chevron {
      mask: url('../../../../src/icons/ph_list-numbers.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }

  & .tox-split-button[aria-label='Bullet list'] {
    & .tox-tbtn.tox-split-button__chevron {
      mask: url('../../../../src/icons/ph_list-bullets.svg');
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }
}

.block-builder,
body {
  & .tox .tox-tbtn[aria-label='Fonts'] {
    width: 120px;
  }

  & .tox .tox-tbtn[aria-label='Bold'] {
    margin-left: 0;
  }

  & .tox-tinymce-inline .tox-editor-header {
    border: none;
  }

  & .tox-editor-container {
    height: 65px;
    border-radius: 5px;
  }
}

.menu-panel__editor {
  margin-top: 24px;

  & .tox .tox-tbtn[aria-label='Font sizes'] {
    width: 96px;
  }

  & .tox .tox-toolbar {
    background: none;
  }

  &--supplementary-text {
    .tox-toolbar__group {
      justify-content: flex-start !important;
    }

    .tox-tbtn[aria-label='Font sizes'] {
      border-right: 1px solid #d5dfe8;
      border-left: 1px solid #d5dfe8;
    }
  }
}

.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border-right: none !important;
}

.new-theme-builder__hf-toolbar {
  & .tox-toolbar__group:first-of-type {
    margin-bottom: 5px;
  }

  & .tox .tox-tbtn[aria-label='Insert/edit link'] {
    margin-left: 0;
  }
}
