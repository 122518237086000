.users-content {
  background-color: white;
  flex: auto;
}

.users-container {
  margin: 20px;
  .butty {
    margin-left: 20px;
    width: 160px;
  }
  .inppy {
    width: 214px;
  }

  .ant-input:placeholder-shown {
    width: 214px;
  }

  .ant-btn {
    line-height: 16px;
  }
}

.users-upper-part {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  align-items: center;
}
