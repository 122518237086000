.choice-btn {
  height: 40px;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: 3px;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $color-dark;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  transition: border 0.2s ease-in-out;
  gap: 10px;

  &:hover {
    border: 1px solid $color-primary;
  }

  &--selected {
    background: $color-primary-3;
    border: 1px solid $color-primary !important;
    color: $color-primary;
  }

  &--sm {
    height: 32px;
  }

  &--left {
    border-radius: 3px 0 0 3px;
  }

  &--right {
    border-radius: 0 3px 3px 0;
  }

  &--middle {
    border-radius: 0;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}

.choice-btn-group {
  display: flex;

  & .choice-btn {
    flex-basis: 33.33%;
  }
}