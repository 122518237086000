label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 425px;
    max-width: 425px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        // padding: 10px 15px;
        padding: 0;
        margin-top: 16px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #216c97;
  background-color: $color-primary;
  border-radius: 3px;
  text-transform: capitalize;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #216c97;
  border-color: #216c97;
}

.panel-heading .panel-title span {
  background-color: #216c97 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}

// input, select { font-size: 100% !important; }

.auth-message {
  font-family: $font-secondary;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  border: 1px solid $color-dark-grey;
  color: $color-dark-grey;
  &--error {
    border: 1px solid $color-warning-red;
    color: $color-warning-red;
  }
  &--success {
    border: 1px solid $color-success-green;
    color: $color-success-green;
  }
}

.ant-select-selection-placeholder {
  text-align: left !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $color-primary;
  box-shadow: none;
}

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
  border: 1px solid $color-primary;
}

.form-wrapper {
  width: 360px;
  max-width: 360px;
  padding: 12px;
}
