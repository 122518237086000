.ant-slider {
  width: 150px;

  & .ant-slider-rail {
    background-color: $color-grey;
  }

  &:hover .ant-slider-track {
    background-color: $color-primary;
  }
  &:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: $color-primary;
  }

  & .ant-slider-track {
    background-color: $color-primary;
  }

  & .ant-slider-handle {
    background-color: $color-primary;
    border-color: $color-primary;
    &:focus {
      box-shadow: 0 0 0 5px $color-primary-inactive;
    }
  }
}