.teams-content {
  background-color: white;
  flex: auto;
}

.teams-container {
  margin: 20px;
  .butty {
    margin-left: 20px;
    width: 160px;
  }
  .inppy {
    width: 214px;
  }

  .ant-input:placeholder-shown {
    width: 214px;
  }

  .ant-btn {
    line-height: 16px;
  }
}

.teams-upper-part {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .left-part {
    display: flex;
    justify-content: flex-start;
  }
  .right-part {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: none;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-right: unset !important;
}
