button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.video-react-button {
  display: none !important;
}

.no-border-btn {
  width: min-content;
  border: none;
  box-shadow: none;

  &__label {
    margin-left: 0.625rem;
    font-weight: 500;
  }

  &:hover,
  &:focus {
    background: none;
  }

  &--homebase-export {
    position: absolute;
    top: 1.5rem;
  }
}
