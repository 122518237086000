.ant-pagination {
    &-item-active {
        border-color: $c-primary;
    
        &:hover {
            border-color: darken($c-primary,5);
    
            a, a:visited {
                color: darken($c-primary,5);
            }
        }
    
        a, a:visited {
            color: $c-primary;
    
            &:hover, &:active {
                text-decoration: none;
            }
        }
    }

    // &-options-size-changer {
    //     &.ant-select {
    //         margin-right: 0;
    //     }
    // }

    li {
        vertical-align: bottom;
    }

    .anticon {
        vertical-align: middle;
    }
}

.ant-table-pagination {
    &.ant-pagination {
        &:last-child {
            margin-bottom: 0;
        }
    }
}