html {
  font-size: 100%;
}

body {
  color: $txtColor !important;
  font-family: $font-stack !important;
  @include font-size(16);
  line-height: 1.2;
  text-size-adjust: 100%;
}

h2 {
  font-size: 16px;
}

hr.big {
  margin: 2rem 0;
  border: 1px solid #dbe2ea;
}

dl {
  dt {
    font-weight: bold;
    margin-right: 0.375em;
    display: inline-block;
    margin-top: 0.625em;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    display: inline;
    &:after {
      content: '\A';
      white-space: pre;
    }
  }
}

.pre-title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #90989e;
  margin: 0;

  &--dark {
    color: $color-dark;
  }

  &__text {
    margin-right: 5px;
  }

  &__info-icon {
    margin-right: 10px;
    svg {
      width: 15px;
      height: 15px;
      color: $color-primary;
    }
  }
}
.advanced-mode {
  .pre-title {
    display: flex;
    align-items: center;
  }
}
